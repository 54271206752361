/** 
 * @Desc: components--重置密码
 */
<template>
  <div class="reset-pwd-card">
    <p class="dialog-title">温馨提示</p>
    <img src="../../assets/new_ui/welcome.png" alt="" class="img-welcome">
    <div class="dialog-content">当前密码为系统默认密码，为保证您的账号安全，请重置密码后再次登录！</div>
    <div class="bottom-btns">
      <el-button
        type="primary"
        size="small"
        @click="$router.push('/personal-center/change-pwd')"
      >立即修改</el-button>
      <el-button size="small" @click="$emit('handlerClose')">跳过</el-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean
    }
  },
  data() {
    return {};
  },
  watch: {
    visible(val, oldVal) {
      if (val === false && oldVal === true) {
      }
    }
  },
  methods: {},
  mounted() {}
};
</script>
<style lang="less">
.reset-pwd-card {
  min-height: 225px;
  text-align: center;
  .dialog-content {
    padding: 10px 84px 50px;
    color: #448816;
    line-height: 20px;
    font-size: 15px;
    font-family: DFPFangYuanW7;
    text-align: center;
  }
  .bottom-btns {
    margin-bottom: 20px;
    text-align: center;
    .el-button {
      width: 175px;
      & + .el-button {
        margin-left: 50px;
      }
    }
  }
}
</style>
