/** 
 * @Desc: components--登录
 */
<template>
  <div class="login-card">
    <p class="dialog-title">登录</p>
    <el-form label-position="right" ref="ruleForm" label-width="100px" class="self-el-form">
      <el-form-item label="手机号：">
        <el-input v-model.trim="loginName" placeholder="请输入手机号" id="loginInput" class="login-input" ref="loginNameRef"
          :maxlength="11" v-enterNumber @input="_inputChange('name')">
          <transition name="fade" slot="prefix">
            <span v-if="errorType1" class="error color-red">{{ errorType1 }}</span>
          </transition>
        </el-input>
      </el-form-item>
      <el-form-item label="密码：">
        <el-input v-model.trim="password" placeholder="请输入密码" class="login-input" :type="inputType" ref="passwordRef"
          :maxlength="14" @input="_inputChange('pwd')">
          <transition name="fade" slot="prefix">
            <span v-if="errorType2" class="error color-red">{{ errorType2 }}</span>
          </transition>
        </el-input>
        <!-- 忘记密码 -->
        <span class="forget-pwd font-family-normal" @click="_click('pwd')">忘记密码</span>
      </el-form-item>
    </el-form>
    <!-- 登录按钮 -->
    <div class="login-btn-box clear-float" v-show="!verifyTrue">
      <el-button v-if="!loginFlag" type="primary" class="clickable button-item" @click="$_login_verify">登录</el-button>
      <el-button v-else type="primary" class="button-item disabled-btn" disabled>登录中
        <div class="icons">
          <span class="icon icon1">.</span>
          <span class="icon icon2">.</span>
          <span class="icon icon3">.</span>
        </div>
      </el-button>
    </div>
    <el-popover v-show="verifyTrue" v-model="popoverVisible" placement="top" title="" trigger="click" content=""
      popper-class="verify-card-popover" @after-leave="_popoverHide">
      <div class="top-box">
        <span class="card-title float-left">完成拼图验证</span>
        <i class="iconfont iconclose float-right cursor" @click.stop="_closeVerify"></i>
        <span class="change-it float-right" @click.stop="_refreshVerify">
          <img class="change-img" src="../../assets/new_ui/change.png" alt="">
          <i class="iconfont iconrefresh"></i>换一张
        </span>
      </div>
      <div id="mpanel4"></div>
      <div class="login-btn-box clear-float" slot="reference">
        <el-button type="primary" class="clickable button-item" :loading="loginFlag"
          @click="!loginFlag && $_login_verify()">登录{{ loginFlag ? '中' : '' }}</el-button>
        <el-button v-if="false" type="primary" class="button-item disabled-btn" disabled>登录中
          <div class="icons">
            <span class="icon icon1">.</span>
            <span class="icon icon2">.</span>
            <span class="icon icon3">.</span>
          </div>
        </el-button>
        <span class="login-btn-mask" v-if="popoverVisible || loginFlag" @click.stop></span>
      </div>
    </el-popover>
    <!-- 注册 -->
    <div :class="{ 'register-account': true }">
      <span class="font-family-normal">还没有账号？</span>
      <span class="span-register cursor font-family-normal" @click="_click('register')">立即注册</span>
    </div>
  </div>
</template>
<script>
import bus from "utils/bus";
import verify from "utils/verify";
import store from "store";
import { mapState } from "vuex";
import { Base64 } from "js-base64";
import Cookies from "js-cookie";
import { reqLogin, reqUpdateAgeGroup } from "api/personal-center";
import {
  CookieEnable,
  localStorageUtil,
  validataPhone,
  LastSelectCheck
} from "utils/util";
import verifyImage1 from "assets/verify-images/1.png";
import verifyImage2 from "assets/verify-images/2.png";
import verifyImage3 from "assets/verify-images/3.png";
import verifyImage4 from "assets/verify-images/4.png";
import verifyImage5 from "assets/verify-images/4.png";
export default {
  props: {
    visible: {
      type: Boolean
    }
  },
  data() {
    return {
      loginName: "",
      password: "",
      rememberPwd: true,
      loginErrorText: "",
      errorType1: "",
      errorType2: "",
      loginFlag: false,
      popoverVisible: false,
      verifyObj: null,
      inputType: "text"
    };
  },
  computed: {
    ...mapState(["isLogin", "accountInfo"]),
    verifyTrue() {
      let validateLoginName = this._validateAccouts(this.loginName);
      let validatePassword = this._validatePassword(this.password);
      if (!validateLoginName && !validatePassword) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    visible(val, oldVal) {
      if (val === false && oldVal === true) {
        this.loginFlag = false;
        this._clear("name");
        this._clear("pwd");
      }
    },
    isLogin(val, oldVal) {
      if (val !== oldVal && !val) {
        this.inputType = "text";
        setTimeout(() => {
          this.inputType = "password";
        }, 500);
      }
      if (!val && oldVal) {
        bus.$emit("Reload");
      }
    }
  },
  methods: {
    /**
     * 切换组件
     * */
    _click(type) {
      this.$emit("handlerComponent", type);
    },
    /**
     * 清空
     * */
    _clear(type) {
      if (type === "name") {
        this.loginName = "";
      } else if (type === "pwd") {
        this.password = "";
      } else {
        this.loginName = "";
        this.password = "";
      }
      this._inputChange(type);
    },

    /**
     * 输入change
     * */
    _inputChange(type) {
      if (type === "name") {
        this.errorType1 = "";
      } else if (type === "pwd") {
        this.errorType2 = "";
      } else {
        this.errorType1 = "";
        this.errorType2 = "";
      }
    },

    /**
     * 关闭滑动验证
     * */
    _closeVerify() {
      this.popoverVisible = false;
    },

    /**
     * 隐藏popover完毕后触发
     * */
    _popoverHide() {
      $("#mpanel4").html("");
    },

    /**
     * 刷新滑动验证
     * */
    _refreshVerify() {
      if (this.verifyObj) {
        this.verifyObj.refresh();
      }
    },

    /**
     * 登录前的校验
     * */
    $_login_verify() {
      if (!CookieEnable()) {
        this.$message.error("对不起，您的浏览器的Cookie功能被禁用，请开启");
        return;
      }
      this.errorType1 = "";
      this.errorType2 = "";
      let validateLoginName = this._validateAccouts(this.loginName);
      let validatePassword = this._validatePassword(this.password);
      if (validateLoginName === 1 && validatePassword === 1) {
        this.$refs.loginNameRef.focus();
        this.errorType2 = "请输入手机号和密码";
      } else if (validateLoginName === 1) {
        this.$refs.loginNameRef.focus();
        this.errorType1 = "请输入手机号";
        // this.errorType2 = "请输入手机号和密码";
      } else if (validateLoginName === 2 && validatePassword) {
        this.$refs.loginNameRef.focus();
        this.errorType2 = "手机号或密码错误";
      } else if (validateLoginName === 2) {
        this.$refs.loginNameRef.focus();
        this.errorType2 = "手机号或密码错误";
      } else if (!validateLoginName && validatePassword === 1) {
        this.$refs.passwordRef.focus();
        this.errorType2 = "请输入密码";
        // this.errorType2 = "请输入手机号和密码";
      } else if (!validateLoginName && validatePassword === 2) {
        this.$refs.passwordRef.focus();
        this.errorType2 = "手机号或密码错误";
      }
      if (this.errorType1 || this.errorType2) {
        return;
      }
      this._verify();
    },

    /**
     * 发送登录请求
     * */
    $_login_http() {
      this.loginFlag = true;
      const obj = {
        username: this.loginName,
        // password: Base64.encode(this.password)
        password: this.password
      };
      return reqLogin(obj)
        .then(response => {
          if (response.status === 200) {
            let now = new Date();
            now.setTime(now.getTime() + response.data.expires_in * 1000);
            Cookies.set("family_token", response.data.access_token, {
              expires: now
            });
            // document.cookie = "family_token=" + response.data.access_token + "; expires=" + now +  "; path=/";
            // store.commit("SYNC_HISTORY", false);
            // store.dispatch("syncHistory");
            if (response.data) {
              // console.log(response.data,'---response.data---');
              store.commit("SET_USER", response.data);
              this.$emit("handlerRecord");
              bus.$emit("Reload");
              // if (response.data.params.isDefaultPasswordTips) {
              //   this._click("welcome");
              // } else {
              //   this.$emit("handlerRecord");
              //   bus.$emit("Reload");
              // }
            }
          }
        })
        .catch(error => {
          this.loginFlag = false;
          console.log("error", error);
          this.errorType2 = "手机号或密码错误";
        });
    },

    /**
     * 登录名校验
     * */
    _validateAccouts(val) {
      if (!val) {
        return 1;
      }
      if (val.length > 11) {
        return 2;
      }
      if (val.length < 6) {
        return 2;
      }
      if (val.length == 11 && !validataPhone(val)) {
        return 2;
      }
      return 0;
    },

    /**
     * 登录密码校验
     * */
    _validatePassword(val) {
      if (!val) {
        return 1;
      }

      if (val.length > 14 || val.length < 6) {
        return 2;
      }
      let reg = /^[a-zA-Z0-9]{6,14}$/g;
      if (!reg.test(val)) {
        return 2;
      }
      return 0;
    },

    /**
     * 回车登录
     * */
    _keydownEvent(event) {
      if (event.keyCode == 13) {
        this.$_login_verify();
      }
    },

    /**
     * 初始化滑动校验
     * */
    _verify() {
      let _this = this;
      this.$nextTick(() => {
        $("#mpanel4").slideVerify({
          type: 2, //类型
          vOffset: 3, //误差量，根据需求自行调整
          vSpace: 3, //间隔
          imgUrl: "",
          imgName: [
            verifyImage1,
            verifyImage2,
            verifyImage3,
            verifyImage4,
            verifyImage5
          ],
          imgSize: {
            width: "246px",
            height: "110px"
          },
          blockSize: {
            width: "30px",
            height: "30px"
          },
          barSize: {
            width: "246px",
            height: "38px"
          },
          ready: function (val) {
            _this.verifyObj = val;
          },
          success: function () {
            _this._closeVerify();
            _this.$_login_http();
            // console.log("验证成功，添加你自己的代码！");
            //......后续操作
          },
          error: function () {
            //		        	alert('验证失败！');
          }
        });
      });
    },

    /**
     * 快捷模块跳转
     * */
    _moduleChange(item) {
      this.$router.push(item.path);
    }
  },
  mounted() {
    setTimeout(() => {
      this.inputType = "password";
    }, 500);
  }
};
</script>
<style lang="less">
.login-card {
  .forget-pwd {
    position: absolute;
    top: 40px;
    left: 230px;
    font-size: 12px;
    color: #9bd277;
    cursor: pointer;

    &:hover {
      color: #6aaf3d;
    }
  }

  .login-input {
    width: 280px;
    // width: 35vh;
    // width: 2.8rem;
    // height: 0.36rem;
  }

  .el-input__prefix {
    display: inline-block;
    font-size: 12px;
    height: 40px;
    position: absolute;
    top: 0.4rem;
    left: 2px;
    cursor: pointer;
    overflow: hidden;
    opacity: 1;
  }

  .login-btn-box {
    text-align: center;
    position: relative;

    .button-item {
      width: 280px;
      // margin-left: 0.2rem;
    }

    .login-btn-mask {
      display: inline-block;
      width: 2.8rem;
      width: 280px;

      height: 42px;
      background: transparent;
      position: absolute;
      left: 50%;
      top: 0;
      margin-left: -140px;
    }
  }

  .register-account {
    margin-top: 30px;
    font-size: 12px;
    text-align: center;
    color: #6aaf3d;
    font-family: DFPFangYuanW7 !important;

    .span-register {
      color: #fcae2c;
      font-family: DFPFangYuanW7 !important;

      &:hover {
        color: #ff8626;
        font-family: DFPFangYuanW7 !important;
      }
    }
  }
}
</style>
