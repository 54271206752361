/** 
 * @Desc: components--欢迎
 */
<template>
  <div class="welcome-card">
    <p class="dialog-title">温馨提示</p>
    <div class="dialog-content">
      <img src="../../assets/new_ui/welcome.png" alt="" class="img-welcome">
      <div
        v-if="accountInfo && accountInfo.babies && accountInfo.babies.length"
        class="welcome-list"
      >
        <p v-for="(item, index) in accountInfo.babies" :key="index" class="welcome-text">
          <span>{{item.studentName}}</span>小朋友已成功加入
          <span>{{item.orgName}}</span>
        </p>
      </div>
      <p class="reset-pwd-text">当前密码为系统默认密码，为保证您的账号安全，请重置密码后再次登录！</p>
    </div>
    <div class="bottom-btns">
      <el-button type="primary" size="small" @click="_save('edit')">立即修改</el-button>
      <el-button size="small" @click="_save('know')">知道了</el-button>
    </div>
  </div>
</template>
<script>
import store from "store";
import { mapState } from "vuex";
export default {
  props: {
    visible: {
      type: Boolean
    }
  },
  data() {
    return {};
  },
  watch: {
    visible(val, oldVal) {
      if (val === false && oldVal === true) {
      }
    }
  },
  computed: {
    ...mapState(["isLogin", "accountInfo"])
  },
  methods: {
    _save(type) {
      if (type === "edit") {
        this.$router.push("/personal-center/change-pwd");
      }
      store.commit(
        "SET_USER",
        Object.assign({}, this.accountInfo, {
          isDefaultPasswordTips: false,
          babies: []
        })
      );
      this.$emit("handlerClose");
    }
  },
  mounted() {}
};
</script>
<style lang="less">
.welcome-card {
  .dialog-content {
    font-size: 15px;
    text-align: center;
    margin-bottom: 36px;
    .img-welcome {
      margin-bottom: 16px;
    }
    .welcome-list {
      max-height: 120px;
      overflow-y: auto;
      .welcome-text {
        margin-top: 10px;
        font-family: DFPFangYuanW7;
        color: #448816;
        font-size: 15px;

        span {
          color: #67bea2;
        }
      }
    }

    .reset-pwd-text {
      margin-top: 30px;
      font-family: DFPFangYuanW7;
      color: #448816;
      font-size: 15px;
    }
  }
  .bottom-btns {
    margin-bottom: 20px;
    text-align: center;
    .el-button {
      width: 175px;
      & + .el-button {
        margin-left: 50px;
      }
    }
  }
}
</style>
